const MarkersInfo = [
    {
      yMarkerOffset: -5,
      xMarkerOffset: 0,
      abbreviation: "Yellowstone",
      name: "Yellowstone National Park",
      coordinates: [-111.074, 44.585],
      image: '/yellowstone-image.JPG'
    },
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Glacier", name: "Glacier National Park", coordinates: [-114.406, 48.660], image: '/glacier-image.JPG' },
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Vancouver", name: "Vancouver, B.C.", coordinates: [-123.2634, 49.2579], image: '/vancouver-image.JPG' },
    { yMarkerOffset: 10, xMarkerOffset: 30, abbreviation: "Olympic", name: "Olympic National Park", coordinates: [-124.2006, 47.7854], image: '/olympic-image.JPG' },
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Portland", name: "Portland, OR", coordinates: [-122.9345, 45.5429], image: '/portland-image.JPG' },
    { yMarkerOffset: 0, xMarkerOffset: 35, abbreviation: "Redwoods", name: "Redwoods National Park", coordinates: [-124.3031, 41.4364], image: '/redwoods-image.JPG' },
    { yMarkerOffset: 0, xMarkerOffset: 38, abbreviation: "King Range", name: "King Range National Preserve", coordinates: [-124.1715, 40.1640], image: '/king-range-image.JPG' },
    { yMarkerOffset: 0, xMarkerOffset: 45, abbreviation: "San Francisco", name: "San Francisco, CA", coordinates: [-122.5777, 37.7579], image: '/san-fran-image.JPG' },
    { yMarkerOffset: -8, xMarkerOffset: 0, abbreviation: "Big Sur", name: "Big Sur State Park", coordinates: [-122.0984, 36.3318], image: '/big-sur-image.JPG' },
    { yMarkerOffset: 5, xMarkerOffset: 15, abbreviation: "L.A.", name: "Los Angeles", coordinates: [-118.6919, 34.0207], image: '/LA-image.JPG' },
    { yMarkerOffset: 8, xMarkerOffset: -28, abbreviation: "Sequoia", name: "Sequoia National Park", coordinates: [-118.8031053, 35.8020083], image: '/sequoia-image.JPG'},
    { yMarkerOffset: -7, xMarkerOffset: 25, abbreviation: "Yosemite", name: "Yosemite National Park", coordinates: [-120.2787, 36.9], image: '/yosemite-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 17, abbreviation: "Death Valley", name: "Death Valley National Park", coordinates: [-117.8917763, 36.8], image: '/death-valley-image.JPG'},
    { yMarkerOffset: -4, xMarkerOffset: -20, abbreviation: "Vegas", name: "Las Vegas", coordinates: [-115.3150829, 36.1249185], image: '/vegas-image.JPG'},
    { yMarkerOffset: 13, xMarkerOffset: 7, abbreviation: "Hoover Dam", name: "Hoover Dam, NV", coordinates: [-114.7486345, 35.7], image: '/hoover-dam-image.JPG'},
    { yMarkerOffset: 4, xMarkerOffset: -18, abbreviation: "Zion", name: "Zion National Park", coordinates: [-113.323417, 37.322308], image: '/zion-image.JPG'},
    { yMarkerOffset: -7, xMarkerOffset: -10, abbreviation: "Bryce", name: "Bryce National Park", coordinates: [-112.3183963, 37.573297], image: '/bryce-image.JPG'},
    { yMarkerOffset: 6, xMarkerOffset: 47, abbreviation: "Grand Canyon", name: "Grand Canyon National Park", coordinates: [-113.9642363, 36.2], image: '/grand-canyon-image.JPG'},
    { yMarkerOffset: -7, xMarkerOffset: 10, abbreviation: "Canyonlands", name: "Canyonlands National Park", coordinates: [-110.4610142, 38.2267], image: '/canyonlands-image.JPG'},    
    { yMarkerOffset: 5, xMarkerOffset: 25, abbreviation: "Arches", name: "Arches National Park", coordinates: [-110.2852329, 38.0755093], image: '/arches-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Denver", name: "Denver, CO", coordinates: [-105.1352925, 39.76453], image: '/denver-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Chicago", name: "Chicago, IL", coordinates: [-88.0121441, 41.8339042], image: '/chicago-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Gettysburg", name: "Gettysburg, PA", coordinates: [-77.9444293, 40.450312], image: '/gettysburg-image.JPG'},
    { yMarkerOffset: 5, xMarkerOffset: 17, abbreviation: "D.C.", name: "Washington D.C.", coordinates: [-77.1546592, 38.89378], image: '/DC-image.JPG'},
    { yMarkerOffset: 15, xMarkerOffset: 45, abbreviation: "Fuquay-Varina", name: "Fuquay-Varina, NC", coordinates: [-78.8001839, 35.610934], image: '/fuquay-varina-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Blue Ridge", name: "Blue Ridge, GA", coordinates: [-84.3542044, 34.8671393], image: '/blue-ridge-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Dallas", name: "Dallas, TX", coordinates: [-97.0117262, 32.8209296], image: '/dallas-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Austin", name: "Austin, TX", coordinates: [-98.033586, 30.3080553], image: '/austin-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Orlando", name: "Orlando, FL", coordinates: [-81.5089225, 28.4813989], image: '/orlando-image.JPG'},
    { yMarkerOffset: -5, xMarkerOffset: 0, abbreviation: "Miami", name: "Miami, FL", coordinates: [-80.31739, 25.8210945], image: '/miami-image.JPG'},
  ];

export default MarkersInfo;